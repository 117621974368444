<template>
    <div class="container">
      <h2>Import Chats</h2>
    </div>
</template>
  
<script>
    export default {
      name: 'ImportChats',
      props: {
        msg: String
      }
    }
</script>
  
  
<style scoped>
</style>
  