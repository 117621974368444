import Vue from "vue";
import Vuex from "vuex";

import {
  updateCustomAttribute,
  setContacts,
  setCustomAttributes,
  addContact,
  addTag,
  addBroadcast,
  addTeam,
  deleteTeam,
  addOperator,
  deleteOperator,
  updateOperator,
  updateTeam,
  removeTag,
  addContactAttribute,
  updateContactAttribute,
  updateContact,
  deleteContactAttribute,
  deleteContact,
  setSelectedContact,
  setSelectedAttribute,
  setMessageTemplates,
  addMessageTemplate,
  addCustomAttribute,
  setSelectedTemplate,
  updateTemplate,
  deleteCustomAttribute,
  deleteBroadcastRecipients,
  addTopic,
  updateTopic,
  deleteTopic,
  setSelectedTopic,
  deleteBroadcastMetrics,
  setTopics,
  setBusiness,
  setBroadcasts,
  setBusinessProfile,
  setGeneralSetting,
  setSelectedBusiness,
  setBroadcastRecipients,
  setSelectedBusinessProfile,
  setSelectedGeneralSetting,
  setTeams,
  setOperators,
  setSelectedTeam,
  setSelectedOperator,
  setBroadcastAddableContacts,
  setBroadcastSelectedTemplate,
  setSelectedTemplateTextContent,

  setBroadcastMetrics,

} from "@/store/mutations";

import {
  // api calls start here
  // Contacts
  fetchContactsAction,
  fetchBusinessAction,
  fetchBusinessProfileAction,
  fetchGeneralSettingAction,
  fetchTeamAction,
  fetchOperatorsAction,
  fetchBroadcastsAction,
  fetchBroadcastMetricsAction,

  postContactAction,
  postBusinessAction,
  postBusinessProfileAction,
  postGeneralSettingAction,
  postCustomAttributeAction,
  postContactAttributeAction,
  postOperatorAction,
  postTeamAction,
  postBroadcastAction,
  
  updateBusinessAction,
  updateBusinessProfileAction,
  updateGeneralSettingAction,
  updateContactAction,
  updateContactAttributeAction,
  updateOperatorAction,
  updateTeamAction,

  deleteContactAction,
  deleteContactAttributeAction,
  deleteBusinessAction,
  deleteBusinessProfileAction,
  deleteGeneralSettingAction,
  deleteOperatorAction,
  deleteTeamAction,
  deleteBroadcastRecipientsAction,
  deleteBroadcastMetricsAction,
  // api calls end here

  addContactAction,
  // addCustomAttributeAction,
  addTagsAction,
  removeTagsAction,
  setSelectedContactAction,
  setSelectedAttributeAction,
  fetchMessageTemplatesAction,
  postMessageTemplateAction,
  fetchCustomAttributesAction,
  uploadTemplateToWhatsApp,
  syncTemplatesAction,
  setSelectedTemplateAction,
  updateMessageTemplateAction,
  deleteCustomAttributeAction,
  updateCustomAttributeAction,
  setSelectedTopicAction,
  fetchTopicsAction,
  updateTopicAction,
  deleteTopicAction,
  postTopicAction,
  setSelectedBusinessAction,
  setSelectedBusinessProfileAction,
  setSelectedGeneralSettingAction,
  setSelectedOperatorAction,
  setSelectedTeamAction,
  setBroadcastRecipientsAction,
  setBroadcastAddableRecipientsAction,
  setBroadcastSelectedTemplateAction,
  setSelectedTemplateTextContentAction
  // Define actions for operators, teams, chats, and users similarly
} from "@/store/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    contacts: [],
    operators: [],
    teams: [],
    chats: [],
    users: [],
    business: {},
    businessProfile: {},
    generalSetting: {},
    customAttributes: [],
    topics: [],
    tags: ["Nigeria", "wholesale", "hot deal"],
    messageTemplates: [],
    broadcasts: [],
    broadcastRecipients: [],
    broadcastAddableContacts: [],
    broadcastSelectedTemplate: {},
    selectedTemplatetTextContent: {},
    
    metrics: {},

    selectedContact: {},
    selectedAttribute: {},
    selectedTemplate: {},
    selectedTopic: {},
    selectedBusiness: {},
    selectedBusinessProfile: {},
    selectedGeneralSetting: {},
    selectedTeam: {},
    selectedOperator: {}
  },
  getters: {
    getContacts: (state) => state.contacts,
    getOperators: (state) => state.operators,
    getTeams: (state) => state.teams,
    getChats: (state) => state.chats,
    getUsers: (state) => state.users,
  },
  mutations: {
    setSelectedContact,
    setSelectedAttribute,
    setMessageTemplates,
    setSelectedTemplate,
    setSelectedTopic,
    setSelectedBusiness,
    setSelectedBusinessProfile,
    setSelectedGeneralSetting,
    setSelectedTeam,
    setSelectedOperator,
    setBroadcastSelectedTemplate,
    setSelectedTemplateTextContent,


    setBroadcasts,
    setTeams,
    setOperators,
    setBusinessProfile,
    setGeneralSetting,
    setBusiness,
    setTopics,
    setContacts,
    setCustomAttributes,
    setBroadcastRecipients,
    setBroadcastAddableContacts,
    setBroadcastMetrics,

    updateContact,
    updateContactAttribute,
    updateTemplate,
    updateCustomAttribute,
    updateTopic,
    updateTeam,
    updateOperator,

    addTag,
    addContact,
    addCustomAttribute,
    addContactAttribute,
    addMessageTemplate,
    addTopic,
    addTeam,
    addOperator,
    addBroadcast,

    removeTag,
    
    
    deleteContactAttribute,
    deleteContact,
    deleteCustomAttribute,
    deleteTopic,
    deleteTeam,
    deleteOperator,
    deleteBroadcastRecipients,
    deleteBroadcastMetrics,
  },
  actions: {
    // api calls start here
    // Contacts
    fetchContacts: fetchContactsAction,
    postContact: postContactAction,
    postBroadcast: postBroadcastAction,
    deleteContact: deleteContactAction,
    updateContact: updateContactAction,
    postContactAttribute: postContactAttributeAction,
    updateContactAttribute: updateContactAttributeAction,
    deleteContactAttribute: deleteContactAttributeAction,
    postCustomAttribute: postCustomAttributeAction,
    deleteCustomAttribute: deleteCustomAttributeAction,
    setTopics: fetchTopicsAction,
    setBusiness: fetchBusinessAction,
    setBusinessProfile: fetchBusinessProfileAction,
    setGeneralSetting: fetchGeneralSettingAction,
    setBroadcasts: fetchBroadcastsAction,
    deleteTopic: deleteTopicAction,
    updateTopic: updateTopicAction,
    postTopic: postTopicAction,
    postBusiness: postBusinessAction,
    postBusinessProfile: postBusinessProfileAction,
    postGeneralSetting: postGeneralSettingAction,
    updateBusiness: updateBusinessAction,
    updateBusinessProfile: updateBusinessProfileAction,
    updateGeneralSetting: updateGeneralSettingAction,
    deleteBusiness: deleteBusinessAction,
    deleteBusinessProfile: deleteBusinessProfileAction,
    deleteGeneralSetting: deleteGeneralSettingAction,
    fetchTeams: fetchTeamAction,
    fetchOperators: fetchOperatorsAction,
    updateTeam: updateTeamAction,
    updateOperator: updateOperatorAction,
    deleteTeam: deleteTeamAction,
    deleteOperator: deleteOperatorAction,
    postTeam: postTeamAction,
    postOperator: postOperatorAction,
    
    // api calls end here
    addContact: addContactAction,
    // addCustomAttribute: addCustomAttributeAction,
    addTags: addTagsAction,
    removeTags: removeTagsAction,
    setSelectedContact: setSelectedContactAction,
    setSelectedAttribute: setSelectedAttributeAction,
    setMessageTemplates: fetchMessageTemplatesAction,
    addMessageTemplate: postMessageTemplateAction,
    setCustomAttributes: fetchCustomAttributesAction,
    uploadTemplateToWhatsApp,
    syncTemplates: syncTemplatesAction,
    setSelectedTemplate: setSelectedTemplateAction,
    updateMessageTemplate: updateMessageTemplateAction,
    updateAttribute: updateCustomAttributeAction,
    setSelectedTopic: setSelectedTopicAction,
    setSelectedBusiness: setSelectedBusinessAction,
    setSelectedBusinessProfile: setSelectedBusinessProfileAction,
    setSelectedGeneralSetting: setSelectedGeneralSettingAction,
    setSelectedTeam: setSelectedTeamAction,
    setSelectedOperator: setSelectedOperatorAction,
    setBroadcastRecipients: setBroadcastRecipientsAction,
    deleteBroadcastRecipients: deleteBroadcastRecipientsAction,
    setBroadcastAddableContacts: setBroadcastAddableRecipientsAction,
    setBroadcastSelectedTemplate: setBroadcastSelectedTemplateAction,
    setSelectedTemplateTextContent: setSelectedTemplateTextContentAction,

    setBroadcastMetrics: fetchBroadcastMetricsAction,
    deleteBroadcastMetrics: deleteBroadcastMetricsAction
  },
});
