<template>
  <BaseLayout />
</template>

<script>
  import BaseLayout from '@/components/layouts/DashboardLayout.vue';


  export default{
    components: {
        BaseLayout
    }
}
</script>

